<template>
	<v-container v-if="folder">
		<v-layout column>
			<v-flex shrink>
				<v-text-field v-model.trim="folder.name" :label="$t('name')" maxlength="191" :rules="[rules.noSpecialChar, rules.required]" />
			</v-flex>
			<v-flex shrink>
				<v-select v-model="folder.catalog_themes" :label="$t('themes.documents.themes')" multiple :items="themes" item-text="title" item-value="id">
					<template v-slot:selection="{ item }">
						<v-chip :color="item.color" dark>
							<span v-text="item.title" />
						</v-chip>
					</template>
				</v-select>
			</v-flex>
			<v-flex shrink>
				<v-select v-model="folder.catalog_flags" chip :items="flags" item-text="name" item-value="id" :label="$t('flags')" multiple />
			</v-flex>
			<v-flex shrink>
				<v-select v-model="folder.catalog_tree_tools" chips :items="tools" item-text="name" item-value="id" :label="$t('sa.geds.tools')" multiple />
			</v-flex>
			<v-flex v-if="showDurations">
				<v-select v-model="folder.duration_id" :items="durations" item-text="name" item-value="id" :label="$t('frequency')" />
			</v-flex>
			<v-flex shrink text-xs-right>
				<WButton :disabled="!isValid" flat icon="save" @click="update">{{ $t('actions.save') }}</WButton>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
/* eslint-disable */
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'FolderDetails',
	mixins: [SuperAdminCatalogTreeStructureModuleGuard, Validator],
	props: {
		catalogTreeStructureId: {
			default: () => 0,
			required: false,
			type: Number
		},
		value: {
			default: () => {},
			required: false,
			type: Object
		}
	},
	data: function () {
		return {
			durations: [],
			flags: [],
			folder: {},
			themes: [],
			tools: []
		}
	},
	computed: {
		isValid: function () {
			return this.validateNoSpecialChar(this.folder.name) && this.validateRequired(this.folder.name)
		},
		showDurations: function () {
			let result = null
			let selectedTools = null
			if (this.folder.catalog_tree_tools) {
				selectedTools = this.folder.catalog_tree_tools.map(item => {
					let result = null
					if (isNaN(item)) {
						result = item.id
					} else {
						result = item
					}
					return result
				})
			} else {
				selectedTools = []
			}
			const automaticReplication = this.tools.find(item => item.name === 'is_automatically_replicable')
			const manualReplication = this.tools.find(item => item.name === 'is_manually_replicable')
			if ((automaticReplication && selectedTools.includes(automaticReplication.id)) || (manualReplication && selectedTools.includes(manualReplication.id))) {
				result = true
			} else {
				result = false
			}
			return result
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (val) {
					this.folder = { ...val }
				}
			}
		},
		showDurations: {
			handler: function (val) {
				if (!val) {
					this.folder.duration_id = null
				}
			}
		},
	},
	mounted: function () {
		this.service.listCatalogThemes().then(catalogThemes => {
			this.themes = catalogThemes
		})
		this.service.listTools().then(catalogTreeTools => {
			this.tools = catalogTreeTools
		})
		const params = {
			'except': 'week'
		}
		this.service.listDurations(params).then(durations => {
			this.durations = durations
		})
		this.service.listCatalogFlags().then(flags => {
			this.flags = flags
		})
	},
	methods: {
		update: function () {
			this.service.updateCatalogFolder(this.catalogTreeStructureId, this.value.id, this.folder).then(res => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('documents.folder_updated', 1))
				this.eventBus.emit(this.events.UPDATE_CATALOG_FOLDER, res)
				this.folder = res
			})
		}
	}
}
</script>
